































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsWorkflowCard from '@/modules/workflows/components/VsWorkflowCard/Index.vue'
import CreateWorkflowModal from '@/modules/workflows/components/CreateWorkflowModal/Index.vue'
import { getWorkflows, deleteWorkflow } from '@/api/consoleApi/workflows'
import VsConfirm from '@/components/VsConfirm/Index.vue'
@Component({
    name: 'Workflows',
    components: {
        VsSectionHeader,
        VsLoader,
        VsContainer,
        VsWorkflowCard,
        CreateWorkflowModal,
        VsConfirm,
    },
})
export default class extends Vue {
    private loading = false
    private workflows: any[] = []
    private total = 0
    checkedWorkflows = []

    get orderBy () {
        return [
            {
                label: this.$t('campaigns.index.orderBy.createdAtDesc'),
                value: 'id|desc',
            },
            {
                label: this.$t('campaigns.index.orderBy.createdAtAsc'),
                value: 'id|asc',
            },
        ]
    }

    private pagination: any = {
        page: 1,
        itemsPerPage: 5,
        orderBy: null,
    }

    private filters: {
        search: string
        status: string
        labelTags: any
    } = {
        search: '',
        status: '',
        labelTags: [],
    }

    $refs!: any

    beforeMount () {
        this.getWorkflows()
    }

    @Watch('filters', { deep: true, immediate: false })
    @Watch('pagination', { immediate: true, deep: true })
    async getWorkflows () {
        try {
            const resp = await getWorkflows(this.buildParams())
            this.workflows = resp.data.data
            this.total = resp.data.meta.pagination.total
        } catch (e) {
            console.log(e)
            this.workflows = []
            this.total = 0
        }
    }

    private buildParams () {
        const params: any = {
            orderBy: 'id',
            sortedBy: 'desc',
            page: this.pagination.page,
            limit: this.pagination.itemsPerPage,
            include: 'recipient',
            searchJoin: 'and',
            search: this.buildSearch(),
            searchFields: this.buildSearchFields(),
        }

        if (this.pagination.orderBy) {
            const order = this.pagination.orderBy.split('|')
            params.orderBy = order[0]
            params.sortedBy = order[1]
        }

        return params
    }

    private buildSearch () {
        const search = [
            // this.filters.labelTags.length > 0 ? `label_tags.id:${this.filters.labelTags}` : '',
            this.filters.status.length > 0 ? `status:${this.filters.status}` : '',
            this.filters.search.trim() !== '' ? `name:${this.filters.search}` : '',
        ].filter((el) => el !== '')

        return search.join(';')
    }

    private buildSearchFields () {
        const searchFields = [
            // this.filters.labelTags.length > 0 ? 'label_tags.id:in' : '',
            this.filters.status.length > 0 ? 'status:=' : '',
            this.filters.search.trim() !== '' ? 'name:like' : '',
        ].filter((el) => el !== '')

        return searchFields.join(';')
    }

    async deleteWorkflow (workflowId: any) {
        try {
            await this.$refs.confirmDelete.openConfirm()
        } catch (e) {
            return
        }

        this.loading = true

        try {
            await deleteWorkflow(workflowId)
            await this.getWorkflows()
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    async workflowCreated (workflowId: any) {
        this.$router.push({
            name: 'editWorkflow',
            params: {
                workflowId,
            },
        })
    }
}
